import { render, staticRenderFns } from "./DataAnalysis.vue?vue&type=template&id=50d23115&scoped=true&"
import script from "./DataAnalysis.vue?vue&type=script&lang=js&"
export * from "./DataAnalysis.vue?vue&type=script&lang=js&"
import style1 from "./DataAnalysis.vue?vue&type=style&index=1&id=50d23115&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50d23115",
  null
  
)

export default component.exports